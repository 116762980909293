@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  background-color: black;
  color: white;
  font-family: 'Urbanist', sans-serif;
}

#nav-gradient {
  background: rgb(12, 28, 53);
  background: linear-gradient(
    160deg,
    rgba(20, 41, 74, 0.875) 0%,
    rgba(11, 18, 55, 0.83043154761904767) 100%
  );
}
